import * as React from "react";

function InstagramIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={40} height={40} viewBox="0 0 40 40" fill="none" {...props}>
      <g clipPath="url(#clip0_505_2998)">
        <path
          d="M28.034 0H11.894C8.7403 0.0037 5.71688 1.25828 3.48706 3.48848C1.25724 5.71866 0.00318 8.7423 0 11.896V28.036C0.0037 31.1896 1.25828 34.2132 3.48848 36.443C5.71866 38.6728 8.7423 39.9268 11.896 39.93H28.036C31.1896 39.9262 34.2132 38.6718 36.443 36.4416C38.6728 34.2114 39.9268 31.1876 39.93 28.034V11.894C39.9262 8.7403 38.6718 5.71688 36.4416 3.48706C34.2114 1.25724 31.1876 0.00318 28.034 0ZM35.914 28.034C35.914 29.0688 35.7102 30.0936 35.3142 31.0496C34.9182 32.0056 34.3378 32.8742 33.606 33.606C32.8742 34.3378 32.0056 34.9182 31.0496 35.3142C30.0936 35.7102 29.0688 35.914 28.034 35.914H11.894C9.80444 35.9134 7.80064 35.083 6.3233 33.6052C4.84594 32.1276 4.016 30.1236 4.016 28.034V11.894C4.01654 9.80444 4.84698 7.80064 6.3247 6.3233C7.80244 4.84594 9.80644 4.016 11.896 4.016H28.036C30.1256 4.01654 32.1294 4.84698 33.6068 6.3247C35.084 7.80244 35.914 9.80644 35.914 11.896V28.034Z"
          fill="#191919"
        />
        <path
          d="M19.9645 9.63794C17.2275 9.64218 14.6037 10.7315 12.6684 12.6672C10.7331 14.6028 9.64433 17.2268 9.64062 19.964C9.64379 22.7018 10.7327 25.3266 12.6684 27.2628C14.6042 29.1988 17.2287 30.2882 19.9665 30.292C22.7047 30.2888 25.3299 29.1996 27.2661 27.2634C29.2023 25.3272 30.2915 22.7022 30.2945 19.964C30.2903 17.2262 29.2005 14.6017 27.2639 12.6663C25.3275 10.7309 22.7025 9.64058 19.9645 9.63794ZM19.9645 26.276C18.2911 26.276 16.6861 25.6112 15.5028 24.4278C14.3194 23.2444 13.6546 21.6394 13.6546 19.966C13.6546 18.2924 14.3194 16.6874 15.5028 15.5041C16.6861 14.3207 18.2911 13.6559 19.9645 13.6559C21.6381 13.6559 23.2431 14.3207 24.4265 15.5041C25.6097 16.6874 26.2745 18.2924 26.2745 19.966C26.2745 21.6394 25.6097 23.2444 24.4265 24.4278C23.2431 25.6112 21.6381 26.276 19.9645 26.276Z"
          fill="#191919"
        />
        <path
          d="M30.3119 12.1899C31.6783 12.1899 32.7859 11.0823 32.7859 9.71594C32.7859 8.3496 31.6783 7.24194 30.3119 7.24194C28.9457 7.24194 27.8379 8.3496 27.8379 9.71594C27.8379 11.0823 28.9457 12.1899 30.3119 12.1899Z"
          fill="#191919"
        />
      </g>
    </svg>
  );
}

const MemoInstagramIcon = React.memo(InstagramIcon);
export default MemoInstagramIcon;
