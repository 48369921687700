import * as React from "react";

function CloseIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={16} height={16} viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M9.18653 8.00046L12.1517 5.03444C12.2318 4.95706 12.2957 4.8645 12.3397 4.76216C12.3837 4.65983 12.4068 4.54976 12.4078 4.43838C12.4087 4.32701 12.3875 4.21655 12.3453 4.11347C12.3032 4.01038 12.2409 3.91672 12.1621 3.83797C12.0834 3.75921 11.9897 3.69692 11.8866 3.65475C11.7835 3.61257 11.6731 3.59135 11.5617 3.59232C11.4503 3.59328 11.3403 3.61642 11.2379 3.66039C11.1356 3.70435 11.043 3.76825 10.9656 3.84836L7.99962 6.81355L5.03444 3.84836C4.95706 3.76825 4.8645 3.70435 4.76216 3.66039C4.65983 3.61642 4.54976 3.59328 4.43838 3.59232C4.32701 3.59135 4.21655 3.61257 4.11347 3.65475C4.01038 3.69692 3.91672 3.75921 3.83797 3.83797C3.75921 3.91672 3.69692 4.01038 3.65475 4.11347C3.61257 4.21655 3.59135 4.32701 3.59232 4.43838C3.59328 4.54976 3.61642 4.65983 3.66039 4.76216C3.70435 4.8645 3.76825 4.95706 3.84836 5.03444L6.81355 7.99962L3.84836 10.9656C3.76825 11.043 3.70435 11.1356 3.66039 11.2379C3.61642 11.3403 3.59328 11.4503 3.59232 11.5617C3.59135 11.6731 3.61257 11.7835 3.65475 11.8866C3.69692 11.9897 3.75921 12.0834 3.83797 12.1621C3.91672 12.2409 4.01038 12.3032 4.11347 12.3453C4.21655 12.3875 4.32701 12.4087 4.43838 12.4078C4.54976 12.4068 4.65983 12.3837 4.76216 12.3397C4.8645 12.2957 4.95706 12.2318 5.03444 12.1517L7.99962 9.18653L10.9656 12.1517C11.043 12.2318 11.1356 12.2957 11.2379 12.3397C11.3403 12.3837 11.4503 12.4068 11.5617 12.4078C11.6731 12.4087 11.7835 12.3875 11.8866 12.3453C11.9897 12.3032 12.0834 12.2409 12.1621 12.1621C12.2409 12.0834 12.3032 11.9897 12.3453 11.8866C12.3875 11.7835 12.4087 11.6731 12.4078 11.5617C12.4068 11.4503 12.3837 11.3403 12.3397 11.2379C12.2957 11.1356 12.2318 11.043 12.1517 10.9656L9.18653 7.99962V8.00046Z"
        fill="#edc308"
      />
    </svg>
  );
}

const MemoCloseIcon = React.memo(CloseIcon);
export default MemoCloseIcon;
