import * as React from "react";

function TelegramIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={40} height={40} viewBox="0 0 40 40" fill="none" {...props}>
      <g xmlns="http://www.w3.org/2000/svg" clipPath="url(#clip0_504_2974)">
        <mask
          id="mask0_504_2974"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="40"
          height="40"
        >
          <path d="M40 0H0V40H40V0Z" fill="white" />
        </mask>
        <g mask="url(#mask0_504_2974)">
          <path
            d="M20 0C31.046 0 40 8.954 40 20C40 31.046 31.046 40 20 40C8.954 40 0 31.046 0 20C0 8.954 8.954 0 20 0ZM28.884 12C28.122 12.014 26.952 12.414 21.326 14.724C17.377 16.3726 13.4389 18.0473 9.512 19.748C8.552 20.126 8.052 20.494 8.006 20.854C7.918 21.546 8.926 21.76 10.194 22.168C11.228 22.5 12.62 22.888 13.344 22.904C14 22.918 14.732 22.65 15.54 22.104C21.06 18.424 23.906 16.566 24.086 16.526C24.212 16.498 24.386 16.462 24.506 16.566C24.624 16.67 24.612 16.866 24.598 16.92C24.498 17.342 19.316 21.996 19.018 22.302L18.874 22.446C17.774 23.532 16.664 24.242 18.58 25.488C20.312 26.614 21.32 27.332 23.1 28.488C24.24 29.224 25.134 30.098 26.31 29.992C26.852 29.942 27.41 29.44 27.696 27.94C28.366 24.4 29.686 16.724 29.99 13.56C30.0083 13.2975 29.997 13.0339 29.956 12.774C29.9313 12.5642 29.8285 12.3714 29.668 12.234C29.426 12.038 29.05 11.998 28.884 12Z"
            fill="#191919"
          />
        </g>
      </g>
    </svg>
  );
}

const MemoTelegramIcon = React.memo(TelegramIcon);
export default MemoTelegramIcon;
